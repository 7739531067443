import React, { useState } from "react";
import { Button } from "@mui/material";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../../utils/AuthStorage";
import Login from "../Login/Login";
import logo from "../../assets/logo.png";

function Header() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!getToken());
  const [showLogin, setShowLogin] = useState(false);

  const toProfile = () => {
    navigate(`/profile`);
  };

  const toHome = () => {
    navigate(`/`);
  };

  const handleLoginClick = () => {
    if (!isLoggedIn) {
      setShowLogin(true);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.header}>
        <h1 className={styles.title}>
          <img src={logo} alt="logo" className={styles.logo} onClick={toHome} />
        </h1>
        <div className={styles.actions}>
          {/* {!isLoggedIn ? (
            <Button variant="contained" color="primary" onClick={handleLoginClick}>
              Login
            </Button>
          ) : (
            <div className={styles.profileIcon} onClick={toProfile}>👤</div>
          )} */}
          <div className={styles.profileIcon} onClick={toProfile}>
            👤
          </div>
        </div>
      </div>

      {showLogin && (
        <Login setIsLoggedIn={setIsLoggedIn} setShowLogin={setShowLogin} />
      )}
    </>
  );
}

export default Header;
