import React, { useState, useEffect } from "react";
import styles from "./ProfilePage.module.css";
import Header from "../Header/Header";
import { clearAuthData } from "../../utils/AuthStorage";
import { useNavigate } from "react-router-dom";
import Login from "../Login/Login";
import axios from "axios";

const Loader = () => {
  return <div className={styles.loader}>Loading...</div>;
};

const ProfilePage = () => {
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleUser = async () => {
    const token = localStorage.getItem("tokenquiz");
    if (!token) {
      navigate("/"); 
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      console.log("User detail fetched Successfully", response.data.data);
      setUser(response.data.data);
    } catch (error) {
      console.error(
        error?.response?.data?.message || "Failed fetching user detail"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // handleUser();
  }, []);

  const [userData, setUserData] = useState({
    name: "UserX",
    avatar: "./user.png",
    coins: 150,
  });

  const handleLogout = () => {
    clearAuthData();
    navigate(`/`);
    setShowLogin(true);
  };

  return (
    <>
      <Header />
      <div className={styles.profileContainer}>
        {/* {loading ? ( 
          <Loader />
        ) : ( */}
          <div>
            <div className={styles.profileHeader}>
              <img
                src={userData.avatar}
                alt="User Avatar"
                className={styles.avatar}
              />
              {/* <h2 className={styles.userName}>{user?.fullname}<br/>{user?.email}</h2> */}
              <h2 className={styles.userName}>User X</h2>
            </div>
            <div className={styles.profileDetails}>
              <p>Coins {localStorage.getItem("score") || 0}</p>
            </div>
            {/* <button onClick={handleLogout} className={styles.logoutButton}>
              Logout
            </button> */}
          </div>
        {/* )} */}
      </div>
      {showLogin && <Login />}
    </>
  );
};

export default ProfilePage;
